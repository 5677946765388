*{
    box-sizing: border-box;
}
/* Hamburger Menu */
.hamburger {
  cursor: pointer;
  width: 24px;
  height: 24px;
  transition: all 0.25s;
  position: relative;
}
.hamburger:hover{
  opacity:0.7;
}

.hamburger-top,
.hamburger-middle,
.hamburger-bottom {
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 2px;
  transform: rotate(0);
  -moz-transform: rotate(0);
  transition: all 0.5s;
 
}

.img-cover{
  object-fit: cover;
  -o-object-fit: cover;
}

/* .hamburger-top.bgg,
.hamburger-middle.bgg,
.hamburger-bottom.bgg {
  background:green !important;
} */

button.open.bg-burgandy#hum{
   background:white !important;
}

.hamburger-middle {
  transform: translateY(7px);
  -moz-transform: translateY(7px);
  
}

.burgandyBehind{
  background: linear-gradient(180deg, rgba(128, 0, 32, 0.2) 59.99%, rgba(0, 0, 0, 0.2) 60%), url("../assets/imgs/burgandybg.jpg");
  background-repeat: no-repeat;
  background-position: center;
}

.hamburger-bottom {
  transform: translateY(14px);
  -moz-transform: translateY(14px);

}

.open {
  transform: rotate(90deg);
  transform:translateY(0px);
}

.open .hamburger-top {
  transform: rotate(45deg) translateY(6px) translate(6px);
}

.open .hamburger-middle {
  display: none;
}

.open .hamburger-bottom {
  transform: rotate(-45deg) translateY(6px) translate(-6px);
}

#menu{
  transition: width .3s ease;
}

.link{
  transition: all ease .1s;   
}

.reception-cont{
  background-image: linear-gradient(35deg, #e8a7a7 50%, #ffeded 50%);
}

.reception-cont .reception-outline{
    border:10px ridge #e8a7a7;
}
.grid_cont{
  display:flex;
  flex-direction: column;
}
.grid_cont div{
  overflow: hidden;
  border:10px solid white;
}

.grid_cont div img{
  width:100%;
  height:100%;
  object-fit: cover;
  -o-object-fit: cover;
  cursor:pointer;  
  border-radius: 15px;
  transition:all ease .5s;
  object-position: center;
}
.grid_cont .one img{
  object-position: 73% 10%;
}
.grid_cont .two img{
  object-position: 73% 20%;
}

.grid_cont .three img{
  object-position: 73% 10%;
}
.grid_cont .five img{
  object-position: 73% 10%;
}

.grid_cont .six img{
  object-position: 30% 33% ;
}

.grid_cont .four img{
  object-position: 90% 15%;
}

.grid_cont .seven img{
  object-position: 90% 15%;
}

.grid_cont div img:hover{
  transform: scale(1.1);
  z-index:10;
}

.loading span{
  animation:loading 2s linear infinite;
}
.loading span:nth-child(1){
  animation-delay: .1s;
}
 .loading span:nth-child(2){
  animation-delay: .2s;
}
 
.loading span:nth-child(3){
  animation-delay: .3s;
}

.loading span:nth-child(4){
  animation-delay: .4s;
}

.loading span:nth-child(5){
  animation-delay: .5s;
}

.loading span:nth-child(6){
  animation-delay: .6s;
}
.loading span:nth-child(7){
  animation-delay: .7s;
}
.loading span:nth-child(8){
  animation-delay: .8s;
} 
.caro-cont .carousel {
  width: 100%;
  margin: auto;
}
/* .caro-cont .carousel div{
  max-height: 70vh ;
} */
.caro-cont .carousel img {
   height: 100%;
}

/* Donate Button */
.btn2 span:first-child{
  transform: translateX(-101%);
  transition: transform .3s ease-in
}
.btn2:hover span{
  transform: translateX(0)
}


@keyframes loading {
    0%{
      opacity: 0;
    }
    100%{
      opacity:1;
    }
 }



@media only screen and (min-width:600px){
  .grid_cont{
      display:grid;
      grid-template-columns: repeat(2,1fr);
  }
  .grid_cont div{
      border-width:10px;
  }
  
}

@media only screen and (min-width:750px){
  .caro-cont .carousel{
    width:65%;
  }
  /* .caro-cont .carousel div{
    max-height: 60vh;
  } */
}

@media only screen and (min-width:960px){
  .caro-cont .carousel{
    width:50%;
  }
  .caro-cont .carousel div{
    max-height: 66vh;
    
  }

    .grid_cont{
      grid-template-columns: none;
      grid-template-areas: "one two three four"
                            "five six six seven";
    }
    .grid_cont .one{
        grid-area: one;
    }
    .grid_cont .two{
       grid-area:two;
    }
    .grid_cont .three{
      grid-area:three;
    }
    .grid_cont .four{
      grid-area:four;
    }
    .grid_cont .five{
      grid-area:five;
    }
    .grid_cont .six{
      grid-area:six;
    }
    .grid_cont .seven{
      grid-area:seven;
    }
}