@font-face{
  font-family: "PoppinsBold";
  src:local("Poppins-Medium"), url("./assets/fonts/Poppins-Medium.ttf") format("truetype");
}
@font-face{
  font-family: "Sofia";
  src:local("Sofia-Regular"), url("./assets/fonts/Sofia-Regular.ttf") format("truetype");
}
@font-face{
  font-family:"gv";
  src:local("GreatVibes"),url("./assets/fonts/GreatVibes-Regular.ttf") format("truetype")
}
html .active-modal,body.active-modal{
  overflow-y: hidden;
}